import * as Sentry from "@sentry/gatsby";

Sentry.init({
    dsn: "https://9824f9de408dcf926f8fc0177050e996@o4507968957382656.ingest.us.sentry.io/4507969141866496",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
          }),      
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
